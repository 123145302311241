.Nav-logo{
    width: 50px;
}

.sidebar ul li a:hover{
    color: grey !important;
    background-color: rgb(0, 189, 252) !important;
  }

  #main{
    padding-left: 0rem !important;
    padding-right: 0 !important;
  }

.sidebar{
    /* position: absolute;
    z-index: 999;
    display: flex; */
    align-items: center;
    /* width: 100%; */
    background-color: rgb(140, 224, 252) !important;
    -webkit-box-shadow: 0px 10px 10px 1px rgba(38,132,166,1);
    -moz-box-shadow: 0px 10px 10px 1px rgba(38,132,166,1);
    box-shadow: 0px 10px 10px 1px rgba(38,132,166,1);
}

.nav-link{
  font-weight: bold !important;
  font-size: 1.2rem !important;
}

.submenu {
  padding-left: 1rem !important;
}
/* #sidebar nav ul{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
} */