.App {
  text-align: center;
}

.App-logo {
  margin-top: 150px;
  width: 150px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-body {
  /* background-color: #282c34; */
  
  background-color: rgb(0, 0, 119);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* font-size: calc(10px + 2vmin); */
  color: white;
  
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.seccion{
  margin-top: 40px;
  margin-bottom: 4rem;
  width: 97%;
}

ul{
  list-style: none;
  
}

li a{
  color: white;
  text-decoration:none;
}

.seccion li :hover{
  color: grey;
}

table{
  text-align: left !important;
}

table-hover{
 background-color: #9eeaff;
}
tr :hover{

  cursor: pointer;
}

select{
  text-align: left !important;
}

.Seleccionar_ok{
  --bs-btn-padding-y: 0.7rem !important;
   --bs-btn-padding-x: 1.8rem !important;
    --bs-btn-font-size: 1rem !important;
}

.swal2-modal{
  overflow-x: hidden !important;
}

#inempresa{
  min-width: 250px;
}